import React from 'react';

import { FirebaseLayout } from '../components/Firebase';

import queryString from 'query-string';

import SEO from '../components/SEO';
import StatusChecker from '../components/StatusChecker';

import Layout from '../components/Layout';

function StatusZamowienia({ location }) {
  const orderId = parseInt(queryString.parse(location.search).orderId);

  return (
    <Layout shop={true}>
      <FirebaseLayout>
        <SEO pageTitle="Dziękujemy za złożenie zamówienia" />
        <div className="mb-6 pt-20 px-6">
          <h1 className="font-bold text-3xl md:text-6xl mb-3 text-white">
            Dziękujemy za złożenie zamówienia!
          </h1>
          <hr className="border-b border-gainsboro w-10" />

          <StatusChecker orderId={orderId} />
        </div>
      </FirebaseLayout>
    </Layout>
  );
}

export default StatusZamowienia;
