import React, { useState, useEffect, useContext } from 'react';

import { useCart } from 'react-use-cart';

import { FirebaseContext } from '../components/Firebase';

// Main Component
function StatusChecker({ orderId }) {
  const { firebase } = useContext(FirebaseContext);
  const [checking, setChecking] = useState(true);
  const [status, setStatus] = useState(null);

  const { emptyCart } = useCart();

  useEffect(() => {
    const checkingFunctionInterval = setInterval(() => {
      if (firebase) {
        firebase.checkPayment(orderId).then(response => {
          if (response !== null) {
            setChecking(false);

            if (response === true) {
              setStatus('success');
              emptyCart();
            } else if (response === false) {
              setStatus('failure');
            }
          }
        });
      }
    }, 5000);

    return () => {
      clearInterval(checkingFunctionInterval);
    };
  }, [firebase]);

  console.log('status', status);

  return (
    <div className="bg-white rounded p-6 mt-6">
      <p className="">
        {checking ? (
          <span>Sprawdzamy status płatności...</span>
        ) : status === 'success' ? (
          <span className="text-success">
            Przyjęliśmy Twoje zamówienie! Powiadomimy Cię o dalszych krokach
            drogą mailową.
          </span>
        ) : status === 'failure' ? (
          <span className="text-red">
            Niestety po drodze wystąpił błąd lub płatność nie została
            przetworzona poprawnie. Skontaktuj się z nami, aby wyjaśnić sprawę:{' '}
            <a href="mailto:potock@potock.pl">potock@potock.pl</a>
          </span>
        ) : (
          <span>
            Coś poszło nie tak podczas automatycznego sprawdzania statusu
            płatności. Jeśli w ciągu następnego dnia roboczego nie otrzymasz od
            nas powiadomienia na skrzynkę e-mail o zaksięgowaniu płatności,
            skontaktuj się z nami:{' '}
            <a href="mailto:potock@potock.pl">potock@potock.pl</a>
          </span>
        )}
      </p>
    </div>
  );
}

export default StatusChecker;
